import React, { useState } from "react";
import { TypeAnimation } from "react-type-animation";
import Banner1 from "../../assets/images/banavt1.png";
import Banner2 from "../../assets/images/banavt2.png";
import Banner3 from "../../assets/images/banavt3.png";
import Banner4 from "../../assets/images/banavt4.png";
import Banner5 from "../../assets/images/banavt5.png";
import hero from "../../assets/images/hero_image.png";
import SignUpForm from "../SignUpForm/Main";
import SuccessMessage from "../Success/index";

function Banner() {
  const [ytban, setytban] = useState(false);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);

  return (
    <>
      {isSuccessMessage && <SuccessMessage text={"See you Soon!"} />}
      <section
        style={{ marginTop: "4rem", opacity: isSuccessMessage ? 0.25 : 1 }}
        className="banner_section"
      >
        <div className="container" data-aos="fade-in" data-aos-duration={1000}>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="banner_text">
                <h1 style={{ margin: 0 }}>
                  <TypeAnimation
                    id="typed"
                    sequence={[
                      "#1 Stocks",
                      2000,
                      "#1 ETFs",
                      2000,
                      "#1 Crypto",
                      2000,
                      "#1 Forex",
                      2000,
                    ]}
                    style={{
                      whiteSpace: "pre",
                      background: "linear-gradient(to right, #1453d9, #fff)",
                      WebkitBackgroundClip: "text",
                      color: "transparent",
                    }}
                    repeat={Infinity}
                  />
                </h1>
                <h2
                  style={{
                    background: "linear-gradient(to right, #fff, #EEEEEE)",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                  }}
                >
                  {"AI Matching App"}
                </h2>

                <p
                  style={{
                    background: "linear-gradient(to right, #D7D7D4, #EEEEEE)",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                    opacity: 0.7,
                  }}
                >
                  {"Covering All Top Investing Channels, For Free!"}
                </p>
                <div className="used_app">
                  <ul>
                    <li>
                      <img src={Banner3} alt="image" />
                    </li>
                    <li>
                      <img src={Banner4} alt="image" />
                    </li>
                    <li>
                      <img src={Banner5} alt="image" />
                    </li>
                    <li>
                      <img src={Banner2} alt="image" />
                    </li>
                    <li>
                      <img src={Banner1} alt="image" />
                    </li>
                  </ul>
                </div>
                <p style={{ opacity: 0.7 }}>
                  Swipe left or right and instantly find the best opportunities
                  for you.
                </p>
              </div>
              <SignUpForm setIsSuccessMessage={setIsSuccessMessage} />
              <div className="trial_box_list">
                <ul>
                  <li>
                    <i className="icofont-check-circled" /> Get full access to
                    everything in our platform
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 d-flex justify-center">
              <div className="banner_slider">
                <div className="hero_img">
                  <img src={hero} alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {ytban && (
        <div
          className="modal fade youtube-video show"
          id="myModal"
          tabIndex={-1}
          style={{ display: "block", paddingRight: 17 }}
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content" onClick={() => setytban(false)}>
              <button
                id="close-video"
                type="button"
                className="button btn btn-default text-right"
                data-dismiss="modal"
                onClick={() => setytban(false)}
              >
                <i className="icofont-close-line-circled" />
              </button>
              <div className="modal-body">
                <div id="video-container" className="video-container">
                  <iframe
                    id="youtubevideo"
                    width={640}
                    height={360}
                    allow="autoplay; encrypted-media"
                    allowFullScreen=""
                    src="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1&controls=0&modestbranding=1&rel=0&showinfo=0&fs=0"
                  />
                </div>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Banner;
